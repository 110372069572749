<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách Trường học</h6>
                  <div>
                    <router-link :to="'/campaign/university/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                    </b-button>
                  </router-link>
                  <a href="/#/campaign/university/upload" class="btn btn-success font-weight-bold btn-sm btn-secondary">
                      <i class="fa fa-plus"></i>
                      Upload File
                  </a>
                  </div>
                 
                </div>
                <b-form @submit.prevent="search()" >
                  <div class="filter-transaction">
                  <div class="row mb-6">
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Mã trường:</label>
                      <input
                          type="text"
                          placeholder="Nhập mã trường"
                          class="form-control datatable-input"
                          v-model="paramFilter.code"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Tên trường:</label>
                      <input
                          type="text"
                          placeholder="Nhập tên trường"
                          class="form-control datatable-input"
                          v-model="paramFilter.name"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Domain:</label>
                      <input
                          type="text"
                          placeholder="Nhập domain trường"
                          class="form-control datatable-input"
                          v-model="paramFilter.domain"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Trạng thái</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.status"
                      >
                        <option value="">Tất cả</option>
                        <option value="1">Kích hoạt</option>
                        <option value="0">Không kích hoạt</option>
                        <option value="2">Chờ duyệt</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mb-6">
                    <b-form-group
                        id="input-group-4"
                        label="Từ ngày:"
                        label-for="dp1"
                        class="col-lg-4"
                    >
                      <date-picker
                          id="dp1"
                          name="dp1"
                          format="YYYY-MM-DD "
                          value-type="format"
                          v-model="paramFilter.from_date"
                          type="date"
                          placeholder="Chọn thời gian"
                      ></date-picker>
                    </b-form-group>
                    <b-form-group
                        id="input-group-4"
                        label="Đến ngày:"
                        label-for="dp1"
                        class="col-lg-4"
                    >
                      <date-picker
                          id="dp1"
                          name="dp1"
                          format="YYYY-MM-DD "
                          value-type="format"
                          v-model="paramFilter.to_date"
                          type="date"
                          placeholder="Chọn thời gian"
                      ></date-picker>
                    </b-form-group>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                      </button>
                    </div>
                  </div>

                </div>
                </b-form>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(stt)="data">
                  <div >
                    {{ data.index + 1}}
                  </div>
                </template>

                <template #cell(code)="data">
                  <div>
                    {{data.item.code }}
                  </div>
                </template>
                
                <template #cell(img)="data">
                  <div>
                    <img width="50" :src="data.item.img" >
                  </div>
                </template>

                <template #cell(event_info)="data">
                  <div v-if="data.item.type == 'COMBO_VOUCHER'">
                    <div>
                      <span>Merchant: </span> <span><b>{{ data.item.client.name ? data.item.client.name: 'N/A' }} </b></span>
                    </div>
                    <div v-if="data.item.event">
                      <span>Campagin: </span> <span><b>{{ data.item.event.name ? data.item.event.name:  'N/A' }} </b></span>
                    </div>
                  </div>
                  <div v-else>
                    <span>N/A</span>
                  </div>

                </template>

                <template #cell(status)="data">
                  <div class="label label-inline label-light-success" v-if="data.item.status == 1">
                    ON
                  </div>
                  <div class="label label-inline label-light-wait" v-else-if="data.item.status == 2">
                    Chờ duyệt
                  </div>
                  <div class="label label-inline label-light-danger" v-else>
                    OFF
                  </div>
                </template>

                <template #cell(created_at)="data">
                  <div>
                    {{formatDate(data.item.created_at)}}
                  </div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                      :to="'/campaign/university/edit/' + data.item.id"
                    >
                      <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa </b-button>
                    </router-link>

                    <b-button
                      @click="destroy(data.item.id)"
                      pill
                      variant="danger"
                      size="sm"
                      >Xóa
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  name: "List.vue",
  mixins: [Common],
  components:{
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "stt",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "code",
          label: "Mã trường",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "img",
          label: "Hình ảnh",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
       
        {
          key: "domain",
          label: "Domain trường",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Ngày tạo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paramFilter:{
        code:'',
        name:'',
        domain:'',
        from_date:'',
        to_date:'',
        status:''
      },
      arrayFilter:[],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý trường học", route: "/campaign/university" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await CampaignRepository.getListUniversity(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list.data;
          this.arrayFilter = body.data.array_filter;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa Trường đại học này không?")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await CampaignRepository.deleteUniversity(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getClients();
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
